<template>
  <div>
    <div style="background-color: #01baef; width: 100%;">
      <div
        class="px-3 pt-3"
        style="align-self: center; justify-content: center; align-items: center;"
      >
        <v-text-field
          v-model="searchText"
          :label="$t('thirdParty.whatAreYouLookingFor')"
          style="padding: 0px !important; margin: 0 !important;"
          solo
          prepend-inner-icon="mdi-magnify"
          @keyup.enter="onSearch"
        />
      </div>
      <div class="px-3 pb-2 d-flex white--text font-weight-bold">
        <span>{{ getActionText(searchTypeSelection) }}</span>
        <span class="text-right cursor-pointer">
          <v-menu
            ref="showMenu"
            v-model="showMenu"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <span
                class="cursor-pointer"
                v-on="on">
                <v-icon color="white">mdi-filter-outline</v-icon>
              </span>
            </template>
            <v-layout column>
              <v-list>
                <v-list-tile
                  v-for="(item, index) in searchTypeList"
                  :key="index"
                  @click="onFilterChanged(item.value)"
                >
                  <v-list-item-icon>
                    <v-icon style="min-width: 32px">
                      {{ getActionIcon(item.value) }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-tile-content class="menu-item">
                    {{ $t(item.text) }}
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-layout>
          </v-menu>
        </span>
      </div>
      <div
        v-if="isAdvanceSearch"
        class="px-3 pb-2 d-flex text--grey-lighten-4 advance-search-form font-small"
      >
        <v-form>
          <v-layout wrap>
            <v-flex
              class="px-1"
              xs12
              md12>
              <v-range-slider
                v-model="rangePrice"
                :max="maxPriceRange"
                :min="'0'"
                :label="$t('thirdParty.price')"
                hide-details
                thumb-size="64px"
                class="align-center"
                append-icon="mdi-replay"
                @change="onMinMaxPriceChanged"
                @click:append="onRemovePriceRange"
              >
                <template v-slot:thumb-label="item">
                  {{ formatMoney(item.value) }}
                </template>
              </v-range-slider>
              <div class="d-flex justify-end mb-1">
                <span
                  class="text-right font-small"
                  style="align-self: center;"
                >{{ formatMoney(rangePrice[0]) + " VND" }}</span
                >
                <span
                  class="mx-4 align-center justify-center"
                  style="align-self: center;"
                >-</span
                >
                <span
                  class="font-small"
                  style="align-self: center;">{{
                    formatMoney(rangePrice[1]) + " VND"
                  }}</span>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm4
              md4>
              <v-autocomplete
                :items="provinceList"
                v-model="provinceSelected"
                :label="$t('siteManagement.province')"
                class="required mx-1"
                @change="onChangedProvince"
              >
                <template
                  slot="selection"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
                <template
                  slot="item"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex
              xs12
              sm4
              md4>
              <v-autocomplete
                :items="districtList"
                v-model="districtSelected"
                :label="$t('siteManagement.district')"
                class="required mx-1"
                @change="onChangedDistrict"
              >
                <template
                  slot="selection"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
                <template
                  slot="item"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex
              xs12
              sm4
              md4>
              <v-autocomplete
                :items="wardList"
                v-model="wardSelected"
                :label="$t('siteManagement.ward')"
                class="required mx-1"
                @change="onChangedWard"
              >
                <template
                  slot="selection"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
                <template
                  slot="item"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex
              class="px-1 pt-1"
              xs12
              md2>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </div>
    </div>
    <v-container
      fluid
      grid-list-md
      text-xs-center>
      <v-layout
        row
        wrap>
        <v-flex
          v-for="(product, index) in productList"
          :key="product + index"
          xs6
          md3
        >
          <div style="width: 100%; min-height: 210px; ">
            <div
              class="mx-auto"
              style="border-radius: 10px; overflow: hidden;">
              <figure
                class="cursor-pointer sixteen-nine-img"
                @click="goProductDetail(product.id, product.issuanceId)"
              >
                <img
                  :src="product.image"
                  :alt="product.name" >
              </figure>
            </div>
            <div class="text-left mt-2">
              <span>{{ product.name }}</span>
              <div class="font-weight-bold mt-1">
                <span
                  v-if="product.pricePromo"
                  style="color: #ff6d6d;"
                >{{ formatMoney(product.pricePromo) }} VND</span
                >
                <span
                  :class="[`d-block`, !product.pricePromo ? '' : '']"
                  :style="
                    product.pricePromo
                      ? 'text-decoration: line-through; font-size: 12px; font-weight: normal; color: #4B4B4B;'
                      : 'color: #ff6d6d;'
                  "
                >{{ formatMoney(product.price) }} VND</span
                >
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
      <snack-bar
        v-for="snackbar in GET_SNACK_BAR"
        :key="snackbar.id"
        :item="snackbar"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import StoreChannelType from 'enum/storeChannelType'
import { isCookieEnabled, getCookie } from 'tiny-cookie'

const SearchActionType = {
  ALL: 0,
  BEST_SELLER: 1,
  NEWEST: 2,
  ADVANCED: 3
}
const RANGE_JUMP = 25000000

export default {
  data: () => ({
    productList: [],
    isAdvanceSearch: false,
    searchText: null,
    searchTypeSelection: SearchActionType.ALL,
    searchTypeList: [
      {
        value: SearchActionType.ALL,
        text: 'thirdParty.allProducts'
      },
      {
        value: SearchActionType.BEST_SELLER,
        text: 'voucherProduct.bestSelling'
      },
      {
        value: SearchActionType.NEWEST,
        text: 'thirdParty.latestProduct'
      },
      {
        value: SearchActionType.ADVANCED,
        text: 'thirdParty.customSearch'
      }
    ],
    maxPriceRange: RANGE_JUMP,
    rangePrice: [0, 0],
    provinceSelected: null,
    districtSelected: null,
    wardSelected: null,
    provinceList: [{ text: '---', value: null }],
    districtList: [{ text: '---', value: null }],
    wardList: [{ text: '---', value: null }],
    showMenu: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'IS_CHANGE_LOGIN_OR_LOGOUT_PARTNERSHIP'
    ])
  },
  watch: {
    IS_CHANGE_LOGIN_OR_LOGOUT_PARTNERSHIP: async function () {
      await this.getProductList()
      await this.getProvinceList()
    }
  },
  async created () {
    // let params = this.$route.params
    await this.getProductList()
    await this.getProvinceList()
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    onMinMaxPriceChanged: function (range) {
      if (range[1] >= this.maxPriceRange) {
        this.maxPriceRange += RANGE_JUMP
      } else if (range[1] + RANGE_JUMP < this.maxPriceRange) {
        this.maxPriceRange = Math.ceil(range[1] / RANGE_JUMP) * RANGE_JUMP
      }
    },
    getActionIcon: function (actionType) {
      if (actionType === this.searchTypeSelection) {
        return 'mdi-check'
      } else {
        return 'mdi-blank'
      }
    },
    getActionText: function (actionType) {
      for (let i = 0, size = this.searchTypeList.length; i < size; i++) {
        if (actionType === this.searchTypeList[i].value) {
          return this.$t(this.searchTypeList[i].text)
        }
      }
      return ''
    },
    onSearch: function () {
      this.getProductList()
    },
    onFilterChanged: function (actionType) {
      this.searchTypeSelection = actionType
      this.isAdvanceSearch = actionType === SearchActionType.ADVANCED
      if (!this.isAdvanceSearch) {
        this.getProductList()
      }
    },
    getProductList: async function () {
      var minPrice = null
      var maxPrice = null
      var provinceCode = null
      var districtCode = null
      var wardCode = null

      if (this.isAdvanceSearch) {
        if (this.rangePrice[0] > 0) {
          minPrice = this.rangePrice[0]
        }
        if (this.rangePrice[1] > 0) {
          maxPrice = this.rangePrice[1]
        }
        provinceCode = this.provinceSelected
        districtCode = this.districtSelected
        wardCode = this.wardSelected
      }
      let filter = {
        params: {
          filterType: this.searchTypeSelection,
          searchText: this.searchText,
          provinceCode: provinceCode,
          districtCode: districtCode,
          wardCode: wardCode,
          minPrice: minPrice,
          maxPrice: maxPrice,
          storeChannelId: this.getStoreChannelId()
        }
      }

      await this.GET_VOUCHER_PRODUCT_ON_MOBILE_PORTAL(filter).then(
        function (res) {
          let data = res.data
          this.productList = []
          for (let i = 0, size = data.length; i < size; i++) {
            // if (data[i].warehouse) {
            let obj = {
              id: data[i].id,
              name: functionUtils.ellipsisLongText(data[i].name, 35),
              image: data[i].image_url,
              price: data[i].value,
              code: data[i].code,
              pricePromo: data[i].extra_info.product.price_promo,
              issuanceId: data[i].issuance_id
            }
            this.productList.push(obj)
            // }
          }
        }.bind(this)
      )
    },
    getProvinceList: function () {
      this.GET_LIST_PROVINCE({ id: 84 }).then(
        function (res) {
          var isFound = false
          let data = res.data
          this.provinceList = [{ text: '---', value: null }]
          for (let i = 0, size = data.length; i < size; i++) {
            let provinceObj = {
              value: data[i].code,
              text: data[i].type + ' ' + data[i].name
            }
            this.provinceList.push(provinceObj)
            if (data[i].code === this.provinceSelected) {
              isFound = true
            }
          }
          if (!isFound) {
            this.provinceSelected = 0
          }
          this.getDistrictList()
        }.bind(this)
      )
    },
    getDistrictList: function () {
      if (this.provinceSelected > 0) {
        this.GET_LIST_DISTRICT({ id: this.provinceSelected }).then(
          function (res) {
            var isFound = false
            let data = res.data
            this.districtList = [{ text: '---', value: null }]
            for (let i = 0, size = data.length; i < size; i++) {
              let districtObj = {
                value: data[i].code,
                text: data[i].type + ' ' + data[i].name
              }
              this.districtList.push(districtObj)
              if (data[i].code === this.districtSelected) {
                isFound = true
              }
            }
            if (!isFound) {
              this.districtSelected = 0
            }
            this.getWardList()
          }.bind(this)
        )
      } else {
        this.districtList = [{ text: '---', value: null }]
        this.districtSelected = 0
        this.getWardList()
      }
    },
    getWardList: function () {
      if (this.districtSelected > 0) {
        this.GET_LIST_WARD({ id: this.districtSelected }).then(
          function (res) {
            var isFound = false
            let data = res.data
            this.wardList = [{ text: '---', value: null }]
            for (let i = 0, size = data.length; i < size; i++) {
              let wardObj = {
                value: data[i].code,
                text: data[i].type + ' ' + data[i].name
              }
              this.wardList.push(wardObj)
              if (data[i].code === this.wardSelected) {
                isFound = true
              }
            }
            if (!isFound) {
              this.wardSelected = 0
            }
          }.bind(this)
        )
      } else {
        this.wardList = [{ text: '---', value: null }]
        this.wardSelected = 0
      }
    },
    /**
     * Go product detail
     */
    onAddToCart: function (product) {
      let productInfo = {
        id: product.id,
        image: product.image,
        quantity: 1,
        price: product.price,
        pricePromo: product.pricePromo,
        name: product.name,
        code: product.code
      }
      this.ADD_PRODUCT_TO_CART(productInfo).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    goProductDetail: function (productId, issuanceId) {
      this.$router.push({
        name: 'MobilePortalProductDetail',
        params: { id: productId },
        query: {
          issuanceId: issuanceId
        }
      })
    },
    onRemovePriceRange: function () {
      this.rangePrice = [0, 0]
    },
    onChangedProvince: function () {
      if (this.provinceSelected == null) {
        this.provinceSelected = 0
      }
      this.districtSelected = 0
      this.districtList = [{ text: '---', value: null }]
      this.wardSelected = 0
      this.wardList = [{ text: '---', value: null }]
      this.getDistrictList()
    },
    onChangedDistrict: function () {
      if (this.districtSelected == null) {
        this.districtSelected = 0
      }
      this.wardSelected = 0
      this.wardList = [{ text: '---', value: null }]
      this.getWardList()
    },
    onChangedWard: function () {
      if (this.wardSelected == null) {
        this.wardSelected = 0
      }
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return partnershipEntityId
      }
      return entityId
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    getStoreChannelId: function () {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return StoreChannelType.PRODUCT_PORTAL
      }
      return this.storeChannelsIdInRouteParams()
    },
    ...mapActions([
      'GET_VOUCHER_PRODUCT_ON_MOBILE_PORTAL',
      'ADD_PRODUCT_TO_CART',
      'ON_SHOW_TOAST',
      'GET_LIST_PROVINCE',
      'GET_LIST_DISTRICT',
      'GET_LIST_WARD'
    ])
  }
}
</script>

<style lang="scss" scoped>
#about {
  background-color: #f4f7f5;
}
.cursor-pointer {
  cursor: pointer;
}
.category-hover:hover {
  background: #e5e5e5;
  padding: 15px;
  border-radius: 5px;
}
figure.sixteen-nine-img {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
figure.sixteen-nine-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.no-space {
  margin: 0;
  padding: 0;
}
.menu-item:hover {
  cursor: pointer;
  background-color: whitesmoke;
}
.advance-search-form {
  background-color: whitesmoke;
}
</style>
